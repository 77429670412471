import React, { Fragment, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RequiredAction } from '@spacefill/uikit/src/components/TaskNotification';
import { Divider } from '@spacefill/uikit/src/components/Divider';
import { useInfiniteQuery } from '@tanstack/react-query';
import { EmptyState } from '@spacefill/uikit/src/components/EmptyState';
import { Loader } from '@spacefill/uikit/src/components/Loader';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';
import { getWarehouseLabel } from '@spacefill/shared/src/components/warehouses/configuration/Helpers';
import * as Backend from '@spacefill/shared/src/utils/Backend';

import emptyTasksSVG from '../../assets/empty-states/empty-state-task.svg';

import { templates } from './RequiredActionsTemplates';

const PER_PAGE = 10;

export function RequiredActionsList() {
    const { user } = useUserContext();
    const { t } = useTranslation();

    const { data, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: ['todo-tasks'],
        queryFn: ({ pageParam = 0 }) => {
            return Backend.call('todo-tasks', { limit: PER_PAGE, offset: pageParam * PER_PAGE });
        },
        placeholderData: { data: [], count: 0 },
        getNextPageParam: (lastPage) => {
            return lastPage.nextPage ?? undefined; // undefined is important to have `hasNextPage` false
        },
    });

    const observer = useRef();
    const lastProjectElementRef = useCallback(
        (node) => {
            if (isFetchingNextPage) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasNextPage) {
                    fetchNextPage();
                }
            });
            if (node) observer.current.observe(node);
        },
        [isFetchingNextPage, fetchNextPage, hasNextPage]
    );

    const totalCount = data?.pages?.[0]?.count;
    return (
        <div>
            {totalCount === 0 && (
                <EmptyState
                    picture={{
                        src: emptyTasksSVG,
                        width: 121,
                        height: 121,
                    }}
                    paragraph={t('All your upcoming tasks will show up here. Right now, it’s all clear. Great job! ✨')}
                />
            )}
            {(data.pages || []).map((todoTasks, i) => {
                return (
                    <React.Fragment key={i}>
                        {(todoTasks.data || []).map((todoTask) => {
                            if (!(todoTask.taskType in templates)) {
                                return null;
                            }
                            const notificationTemplate = templates[todoTask.taskType];
                            const url = notificationTemplate.url(todoTask);
                            return url ? (
                                <Link
                                    key={`Notification-${todoTask.id}`}
                                    to={notificationTemplate.url(todoTask)}
                                >
                                    <RequiredAction
                                        chipIcon={notificationTemplate.chipIcon(todoTask)}
                                        user={todoTask.createdBy}
                                        receivedAt={new Date(todoTask.createdAt)}
                                        warehouseName={getWarehouseLabel(user, todoTask.order?.warehouse)}
                                    >
                                        {notificationTemplate.content(todoTask)}
                                    </RequiredAction>
                                    <Divider />
                                </Link>
                            ) : (
                                <Fragment key={`Notification-${todoTask.id}`}>
                                    <RequiredAction
                                        chipIcon={notificationTemplate.chipIcon(todoTask)}
                                        user={todoTask.createdBy}
                                        receivedAt={new Date(todoTask.createdAt)}
                                        warehouseName={getWarehouseLabel(user, todoTask.order?.warehouse)}
                                    >
                                        {notificationTemplate.content(todoTask)}
                                    </RequiredAction>
                                    <Divider />
                                </Fragment>
                            );
                        })}
                    </React.Fragment>
                );
            })}
            {hasNextPage && (
                <div ref={lastProjectElementRef}>
                    <Loader />
                </div>
            )}
        </div>
    );
}
